import * as React from "react"
import Layout from "../components/Layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/contact.scss'
import {graphql} from "gatsby";
import {Trans, useI18next} from "gatsby-plugin-react-i18next";
import {Parallax} from "react-scroll-parallax";
import Mapa from "../images/inline/map.inline.svg"
import Sello from "../images/inline/sello.inline.svg"

const ContactPage = () => {
    const {language} = useI18next();

    return (
        <Layout>
            <title>Santaleza</title>

            <section className={'locale-map'}>
                <StaticImage className={'bg'} src="../images/contact/bg-contact-us.webp" alt="Contact Us"/>
                <div className="left">
                    <h1 className="h1"><Trans>Contact us</Trans></h1>
                    <p className="subtitle">We're always happy to hear from you! Whether you have a question about our spirits, want to place an order, or just want to say hello, please don't hesitate to reach out to <a href="mailto:info@santaleza.com">info@santaleza.com</a>. Please be as specific as possible in your email. <br /><br /><Trans>Write us</Trans></p>
                </div>
                <div className="right">
                    <Mapa/>
                    {/* <div className="sello">
                        <Sello></Sello>
                    </div> */}
                </div>
            </section>

            {/* <section className="header-section">

                <section className="contact">
                    {/ <p className="subtt margin-aboutus">Acércate a nosotros</p> /}

                    <div className="contact-title">
                        <StaticImage src="../images/contact/vineta.png" alt="vineta"/>
                        <h1 className="to"><Trans>Contact us</Trans></h1>
                    </div>

                    {/<div className="contact-information">

                        <div className="column-alignment">
                            <div className="horizontal-alignment">
                                <div><StaticImage src="../images/contact/phone.png" alt="phone icon"/></div>
                                <p>+522 222 123 456</p>
                            </div>
                            <div className="horizontal-alignment">
                                <div><StaticImage src="../images/contact/email.png" alt="email icon"/></div>
                                <p>info@santaleza.com</p>
                            </div>
                            <div className="horizontal-alignment">
                                <div><StaticImage src="../images/contact/location.png" alt="location icon"/></div>
                                <p>Calle #5678 Colonia La Paz C.P. 72440 Puebla, Pue.</p>
                            </div>
                        </div>

                        <div className="social-networks">
                            <p><Trans>Social networks</Trans></p>
                            <div>
                                <StaticImage className="margin-icon" src="../images/contact/instagram.png"
                                             alt="instagram icon"/>
                                <StaticImage className="margin-icon" src="../images/contact/facebook.png"
                                             alt="facebook icon"/>
                                 <StaticImage src="../images/contact/twitter.png" alt="twitter icon"/>
                            </div>
                        </div>

                    </div>/}
                </section>

                {/<section className="scroll-down">
                    <div className="position-scroll" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 850, behavior: `smooth`}) : ''
                    }}>
                        <p className="spettw">Scroll down</p>
                        <StaticImage src="../images/contact/down-arrow.png" alt="down arrow"/>
                    </div>
                </section>

                <section className="images-contact">
                    <StaticImage className="image-left-glass" src="../images/contact/glass-left.png" alt="cocktail"/>
                    <StaticImage className="image-right-glass" src="../images/contact/glass-right.png" alt="cocktail"/>
                    <div className="img-1900"><StaticImage src="../images/contact/1900.png" alt="sello 1900"/></div>
                </section>
                /}
            </section> */}

            {/* <section className="form-section">

                <section className="left-section">
                    {/ <h2 className="form-text"><Trans>Can we help</Trans></h2> /}
                    <h2 className="form-text">We're always happy to hear from you! Whether you have a question about our tequila, want to place an order, or just want to say hello, please don't hesitate to reach out to <a href="mailto:info@santaleza.com">info@santaleza.com</a> Please be as specific as possible in your email.</h2>
                    <p className="form-paragraph"><Trans>Write us</Trans></p>

                    {/ <form method="POST">
                        <div className="user-data">
                            <div className="data-container">
                                <input id="name" name="name" placeholder={language === "es" ? "Nombre" : "Name"}/>
                            </div>
                            <div className="data-container">
                                <input id="email" name="email" placeholder={"Email"}/>
                            </div>
                            <div className="data-container">
                                <input id="telephone" name="telephone" placeholder={language === "es" ? "Teléfono" : "Phone"}/>
                            </div>
                        </div>


                        <div className="user-message">
                            <textarea placeholder={language === "es" ? "Mensaje" : "Message"} className="textarea" id="message" name="message"></textarea>
                        </div>

                        <button className="button-enviar tbutton" type="button"><Trans>Send</Trans></button>

                    </form> /}

                </section>

                {/ <StaticImage className="vintage" src="../images/contact/vintage-text.png" alt="vintage text"/> /}

            </section> */}

            <section className={'quote-section'}>

                <div className="bloque2-prueba">
                    <StaticImage className="agavito" src="../images/home/agavito_1.png" alt="agavito"/>
                    <p className="pbloq2 subto"><Trans>The family</Trans></p>
                    <StaticImage className="agavito" src="../images/home/agavito_2.png" alt="agavito"/>
                </div>

                {/* <StaticImage src="../images/ouressence/vintage-text.png" className={'letters'} alt="texto vintage"/> */}

            </section>

            <div className="footer-image-section" style={{ minHeight: '400px' }}>
                <StaticImage src="../images/contact/esencia.webp" alt="cocketeleria"/>
            </div>

            <Parallax speed={50} translateY={[0, 50]} className="fourth-section contact-last">

                <StaticImage className="branding-strong" src="../images/home/the-strong-sanctuary.png"
                             alt="logo sanctuary"/>

                <div className="back-to-top" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 0, behavior: `smooth`}) : ''
                }}>
                    <StaticImage className="up-arrow" src="../images/home/back-top.svg" alt="flecha arriba"/>
                    <p className="parrafo text-back">BACK <br/> TO TOP</p>
                </div>

            </Parallax>

        </Layout>
    )
}

export default ContactPage

export const pageQuery = graphql`
     query ContactQuery ($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`